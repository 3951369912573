import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Menu, Dropdown, Avatar, Image, Modal } from 'antd'
import {
  UserOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

const UserInfo = ({ userInfo, onLogout }) => {
  const history = useHistory()

  const hanldeLogout = () => {
    Modal.confirm({
      title: '是否确认退出此系统?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onLogout()
        history.push('/login')
      }
    })
  }

  const handleToAdmin = () => {
    history.push('/admin/detail/' + userInfo.companyId)
  }

  const menu = (
    <Menu>
      {userInfo.roleType === 2 && (
        <Menu.Item key="1" icon={<UserOutlined />} onClick={handleToAdmin}>
          <span className="f14">公司管理员</span>
        </Menu.Item>
      )}
      <Menu.Item key="0" icon={<LogoutOutlined />} onClick={hanldeLogout}>
        <span className="f14">退出</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomCenter" arrow>
      <div className="user-wrap">
        {userInfo.userAvatar ? (
          <Avatar size={28} src={<Image src={userInfo.userAvatar} />} />
        ) : (
          <Avatar size={28} icon={<UserOutlined />} />
        )}
        <label className="user-name">{userInfo.userName}</label>
      </div>
    </Dropdown>
  )
}

UserInfo.propTypes = {
  userInfo: PropTypes.object,
  onLogout: PropTypes.func
}

export default UserInfo
