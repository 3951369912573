import React from 'react'
import { connect } from 'react-redux'
import SiderLogo from './Logo'
import SiderMenus from './Menus'
import SiderIcons from './Icons'
import { setSiderStatus } from '@/actions/global'
import './index.less'

const layoutSider = ({ hasFold, roles, onFoldChange }) => {
  const foldName = hasFold ? 'is_fold' : 'not_fold'
  return (
    <div className={`layout-sider ${foldName}`}>
      <SiderLogo hasFold={hasFold} />
      <SiderMenus roles={roles} />
      <SiderIcons hasFold={hasFold} onFoldChange={onFoldChange} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    hasFold: state.global.siderStatus,
    roles: state.userInfo.roles
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFoldChange(status) {
      dispatch(setSiderStatus(status))
    }
  }
}

const SiderCombination = connect(
  mapStateToProps,
  mapDispatchToProps
)(layoutSider)

export default SiderCombination
