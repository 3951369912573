import { notification } from 'antd'
import axios from 'axios'
import qs from 'qs'

class Request {
  constructor(options) {
    const defaultConfig = {
      timeout: 5000000,
      contentType: 'form',
      resSuccessCode: 1,
      resDataKey: 'data',
      resMesKey: 'msg',
      onError(msg) {
        alert(msg)
      }
    }
    this.options = Object.assign(defaultConfig, options)
    this.init()
  }

  init() {
    const { options } = this
    this.instance = axios.create({
      baseURL: options.baseUrl,
      timeout: options.timeout
    })
    this.request()
    this.response()
  }

  request() {
    this.instance.interceptors.request.use((config) => {
      const { parameters, options } = this

      if (parameters) {
        parameters.request && parameters.request(config)
        for (const key in options) {
          if (parameters[key]) {
            options[key] = parameters[key]
          }
        }
      }

      switch (options.contentType) {
        case 'form':
          config.headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
          config.data = qs.stringify(config.data)
          break
        case 'json':
          config.headers = {
            'Content-Type': 'application/json'
          }
          config.data = JSON.stringify(config.data)
          break
        case 'multipart':
          config.headers = {
            'Content-Type': 'multipart/form-data'
          }
          // eslint-disable-next-line no-case-declarations
          const formData = new FormData()
          for (const key in config.data) {
            formData.append(key, config.data[key])
          }
          config.data = formData
          break
      }
      options.onBefore && options.onBefore(config)
      return config
    })
  }

  response() {
    this.instance.interceptors.response.use(
      (response) => {
        const data = response.data
        const { options, parameters } = this
        parameters && parameters.response && parameters.response(response)
        return new Promise((resolve, reject) => {
          if (data.code === options.resSuccessCode) {
            resolve(data[options.resDataKey])
          } else {
            options.onError(data[options.resMesKey])
            reject(data)
          }
        })
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response?.data?.msg) {
            notification.error({
              message: '请求失败：' + err.response?.data?.msg
            })
            reject(err.response.data)
          }
        })
      }
    )
  }

  post(parameters) {
    if (!parameters.contentType) {
      this.options.contentType = 'form'
    }
    this.parameters = parameters
    return this.instance.post(parameters.url, parameters.params)
  }

  get(parameters) {
    this.parameters = parameters
    return this.instance.get(parameters.url, { params: parameters.params })
  }
}

export default Request
