/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return
  return JSON.parse(window.localStorage.getItem(name))
}

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 存储Cookie
 */
export const setCookie = (name, value, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toGMTString()
  document.cookie = name + '=' + value + '; ' + expires
}

/**
 * 获取Cookie
 */
export const getCookie = (name) => {
  name = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim()
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

/**
 * 删除Cookie
 */
export const delCookie = (name) => {
  const exp = new Date()
  exp.setTime(exp.getTime() - 1)
  const cval = getCookie(name)
  if (cval != null)
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
}

/**
 * 数组深拷贝
 */
export const copyArray = (arr) => {
  const newobj = arr.constructor === Array ? [] : {}
  if (typeof arr !== 'object') {
    return
  }
  for (const i in arr) {
    newobj[i] = typeof arr[i] === 'object' ? copyArray(arr[i]) : arr[i]
  }
  return newobj
}

/**
 * 获取文件后缀
 */
export const getFileSuffix = (name) => {
  const index = name.lastIndexOf('.')
  return name.substring(index + 1)
}

/**
 * 时间戳转换
 */
export const formatDate = (date, fmt = 'yyyy-MM-dd hh:mm:ss') => {
  const time = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    S: date.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (let k in time) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? time[k]
          : ('00' + time[k]).substr(('' + time[k]).length)
      )
    }
  }
  return fmt
}

/**
 * 获取指定范围随机数
 */
export const getRandom = (start, end) => {
  const length = end - start
  const value = parseInt(Math.random() * length + start)
  return value
}

/**
 * 转换成树状结构
 */
export const toTreeData = (list, parentId) => {
  let len = list.length
  const loop = (parentId) => {
    let result = []
    for (let i = 0; i < len; i++) {
      let item = list[i]
      if (item.parentId === parentId) {
        const children = loop(item.id)
        const element = {
          title: item.name,
          key: item.id,
          value: item.id
        }
        if (children.length > 0) {
          element.children = children
        }
        result.push(element)
      }
    }
    return result
  }
  return loop(parentId)
}

/**
 * 过滤对象的key值
 */
export const getTargetObject = (targetObject, propsArray) => {
  if (typeof targetObject !== 'object' || !Array.isArray(propsArray)) {
    throw new Error('参数格式不正确')
  }
  const result = {}
  Object.keys(targetObject)
    .filter((key) => propsArray.includes(key))
    .forEach((key) => {
      result[key] = targetObject[key]
    })
  return result
}

export const getFreeUrl = ([item]) => {
  if (!item) return ''
  if (item.url) {
    return item.url
  } else if (
    item.response &&
    item.response.data &&
    item.response.data.freeUrl
  ) {
    return item.response.data.freeUrl
  }
  return ''
}

export const setFreeUrl = (url) => {
  return url
    ? [
        {
          url,
          name: url
        }
      ]
    : []
}

export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
