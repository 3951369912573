import React from 'react'
import PropTypes from 'prop-types'
import FullScreen from './FullScreen'
import UserInfo from './UserInfo'
import { BellOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import './index.less'

const HeaderTemplate = ({ userInfo, onLogout }) => {

  return (
    <header className="layout-head">
      <div className="container clearfix">
        <div className="fl_right clearfix">
          <div className="menu-item">
            <FullScreen />
          </div>
          <div className="menu-item">
            <Badge count={3}>
              <BellOutlined />
            </Badge>
          </div>
          <div className="menu-item">
            <UserInfo userInfo={userInfo} onLogout={onLogout} />
          </div>
        </div>
      </div>
    </header>
  )
}

HeaderTemplate.propTypes = {
  userInfo: PropTypes.object,
  getUserInfo: PropTypes.func,
  onLogout: PropTypes.func
}

export default HeaderTemplate
