import { connect } from 'react-redux'
import HeaderTemplate from './Template'
import { removeUserInfo } from '@/actions/userInfo'
import { addUserInfo } from '@/actions/userInfo'
import CONFIG from '@/config/config'
import { delCookie } from '@/assets/scripts/utils'
import { getUserInfo } from '@/servers/global'

const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}

const mapDispatchToProps = (dispatch) => {
  return {
    async getUserInfo() {
      const result = await getUserInfo()
      dispatch(
        addUserInfo(
          Object.assign(result, {
            roles: [CONFIG.ROLES[result.roleType - 1]]
          })
        )
      )
    },
    onLogout() {
      dispatch(removeUserInfo())
      delCookie(CONFIG.COOKIE_KEY)
    }
  }
}

const layoutHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderTemplate)

export default layoutHeader
