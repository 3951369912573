import { combineReducers } from 'redux'
import global from './global'
import userInfo from './userInfo'
import classify from './classify'

const reducers = combineReducers({
  global,
  userInfo,
  classify
})

export default reducers
