import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { BackTop } from 'antd'
import LayoutHeader from '../Header'
import LayoutSider from '../Sider'
import LayoutPage from '../Page'
import LayoutFooter from '../Footer'

const LayoutContainer = ({ hasLayout, children }) => {
  const element = useRef(null)

  return (
    <React.Fragment>
      {hasLayout && <LayoutSider />}
      <main className="layout-container" ref={element}>
        {hasLayout && <LayoutHeader />}
        <LayoutPage noSpace={!hasLayout}>{children}</LayoutPage>
        <LayoutFooter />
      </main>
      <BackTop target={() => element.current} />
    </React.Fragment>
  )
}

LayoutContainer.propTypes = {
  hasLayout: PropTypes.bool.isRequired,
  children: PropTypes.object
}

export default LayoutContainer
