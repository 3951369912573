import React from 'react'
import PropTypes from 'prop-types'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

const SiderIcons = ({ hasFold, onFoldChange }) => {
  return (
    <div className="sider-icons">
      <span
        className="sider-icons-item"
        onClick={() => {
          onFoldChange(!hasFold)
        }}
      >
        {hasFold ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </span>
    </div>
  )
}

SiderIcons.propTypes = {
  hasFold: PropTypes.bool.isRequired,
  onFoldChange: PropTypes.func.isRequired
}

export default SiderIcons
