// import { setStore, getStore } from '@/assets/scripts/utils'

const initialState = {
  siderStatus: true,
  markerStatus: false
}

const global = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDER_STATUS':
      state.siderStatus = action.data
      return { ...state }
    case 'SET_MARKER_STATUS':
      state.markerStatus = action.data
      return { ...state }
    default:
      return state
  }
}

export default global