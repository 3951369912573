const initialState = {
  clothesId: '',
  partsId: ''
}

const classify = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CLOTHES_ID':
      state.clothesId = action.data
      return { ...state }

    case 'SET_PARTS_ID':
      state.partsId = action.data
      return { ...state }

    default:
      return state
  }
}

export default classify
