import React from 'react'
import PropTypes from 'prop-types'
import logo from '@/assets/images/logo2.png'
import logoSmall from '@/assets/images/logo_small.png'

const SiderLogo = ({ hasFold }) => {
  return (
    <a className="sider-logo" href="/#">
      <img className="logo-img" src={hasFold ? logo : logoSmall} />
    </a>
  )
}

SiderLogo.propTypes = {
  hasFold: PropTypes.bool.isRequired
}

export default SiderLogo
