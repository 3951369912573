/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import LayoutContainer from '@/layout/Container'
import { useSelector, useDispatch } from 'react-redux'
import { getUserInfo } from '@/servers/global'
import { addUserInfo } from '@/actions/userInfo'
import Loading from '@/components/Common/Loading'
import CONFIG from '@/config/config'

const renderRoutes = ({ routers }) => {
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.userInfo)
  const roleName = userInfo.roles && userInfo.roles[0]
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      Object.keys(userInfo).length === 0 &&
      pathname !== '/login' &&
      pathname.search(/model\/.+/) === -1
    ) {
      fetchUser()
    }
  }, [pathname])

  const fetchUser = async () => {
    const result = await getUserInfo()

    dispatch(
      addUserInfo(
        Object.assign(result, {
          roles: [CONFIG.ROLES[result.roleType - 1]]
        })
      )
    )
  }

  const redirect = () => {
    if (roleName) {
      if (roleName === 'superAdmin') {
        return <Redirect to="/admin" />
      } else {
        return <Redirect to="/project" />
      }
    } else {
      return <div style={{ width: '100%' }}><Loading /></div>
    }
  }

  const staticRouter = routers.filter((item) => !item.roles)

  const asyncRouter = routers.filter(
    (item) => item.roles && item.roles.includes(roleName)
  )

  const render = (routes) => {
    routes.forEach(item => {
      if (item.path.indexOf('clothing') > -1) {
        item.layout = false
        item.sider = false
      }
    })
    console.log(routes)
    return routes.map((item, index) => (
      <Route
        path={item.path}
        exact={item.exact || true}
        key={index}
        render={(props) => {
          const hasLayout = item.layout === undefined ? true : item.layout
          return (
            <LayoutContainer hasLayout={hasLayout}>
              <item.component {...props} route={item} />
            </LayoutContainer>
          )
        }}
      />
    ))
  }
  return (
    <Switch>
      <Route exact path="/">
        {redirect()}
      </Route>

      {render(staticRouter)}

      {roleName && render(asyncRouter)}

      {roleName && (
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      )}
    </Switch>
  )
}

export default renderRoutes
