import React, { useState } from 'react'
import screenfull from 'screenfull'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'

const FullScreen = () => {
  const [hasFullscreen, setFullscreen] = useState(false)

  const onChange = () => {
    setFullscreen(!hasFullscreen)
    screenfull.toggle()
  }

  return (
    <div className="full-screen" onClick={onChange}>
      {hasFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
    </div>
  )
}

export default FullScreen
