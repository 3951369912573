export default {
  COOKIE_KEY: 'GLINSUN_PMD_TOKEN',
  ROLES: ['superAdmin', 'companyAdmin', 'user'],
  UPLOAD_URL: 'https://aiweb.glinsunai.com/wechat/uploadDocument',
  LIST_PARAMS: {
    page: 1,
    rows: 16,
    sort: 'create_time',
    order: 'desc'
  },
  VERSION_TYPES: {
    1: '西装',
    2: '旗袍',
    3: 'T恤',
    4: '卫衣',
    5: '外套',
    6: '牛仔裤'
  }
}
