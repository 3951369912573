import Request from '@/assets/scripts/request'
import CONFIG from '@/config/config'
import { getCookie } from '@/assets/scripts/utils'
import { message } from 'antd'

const basrUrl = {
  // development: 'http://192.168.60.53:8888/',
  // development: 'http://192.168.60.101:8899/',
  development: '/dev-api',
  production: '/api'
}

const getCurBaseUrl = () => {
  let url = ''
  switch (process.env.NODE_ENV) {
    case 'development':
      url = basrUrl.development
      break
    case 'production':
      url = basrUrl.production
      break
    default:
      break
  }
  return url
}

const request = new Request({
  baseUrl: getCurBaseUrl(),
  onBefore(config) {
    const _token = getCookie(CONFIG.COOKIE_KEY) || 'null'
    if (_token)
    // config.headers.Authorization = 'Bearer ' + _token
    config.headers.Authorization = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJqbC1obGFwZG1XZWIiLCJzdWIiOiJhZG1pbjEiLCJqdGkiOiIxMjMiLCJpYXQiOjE2NDUwODQ2MjYsImV4cCI6MTY0NTY4OTQyNn0.h_qPQ5VTUeSVbBy6Db9x6KXFxcYfvXmdorQhpM3qwOYjOiJyb9KWHDL2xLXkHhEKKB3rfic5F3zoPuwT0-iq6Q'
  },
  onError(msg) {
    if (msg === 'token错误') {
      window.location.href = '/#/login'
    } else {
      message.error(msg)
    }
  }
})

export const UploadModel = new Request({
  // baseUrl: 'http://192.168.60.53:9833/',
  // baseUrl: 'https://aiweb.glinsunai.com/',
  baseUrl:getCurBaseUrl(),
  onBefore(config) {
    console.log(config)
  },
  onError(msg) {
    console.log(msg)
  }
})

export default request
