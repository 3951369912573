/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react'
import { HashRouter } from 'react-router-dom'
import RenderRoutes from './renderRoutes'
import routers from './config'
import Loadable from 'react-loadable'
import Loading from '@/components/Common/Loading'

import { setLogin } from '@/servers/global'
import { setCookie } from '@/assets/scripts/utils'
import CONFIG from '@/config/config'

const Router = () => {
  const getFlatRoutes = (routes) => {
    const _routes = []
    const _each = (routes) => {
      routes.forEach((route) => {
        if (route.childrens) {
          route.childrens.forEach((child) => {
            if (child.path.search(route.path) < 0) {
              child.path = route.path + child.path
              child.roles = child.roles || route.roles
            }
          })
          _each(route.childrens)
        }
        route.component = Loadable({
          loader: route.component,
          loading: Loading
        })
        _routes.push(route)
      })
    }
    _each(routes)
    return _routes
  }
  
  useEffect(() => {
    // geUserInfo()
  }, [])

  const geUserInfo = async () => {
    const values = {
      username: 'admin1',
      password: 'hala1999'
    }
    const result = await setLogin(values)
    setCookie(CONFIG.COOKIE_KEY, result.token, 7)
  }

  return (
    <HashRouter>
      {/* {renderRoutes({
        routers: getFlatRoutes(routers),
        isAuth: true
      })} */}
      <RenderRoutes routers={getFlatRoutes(routers)} isAuth={true}></RenderRoutes>
    </HashRouter>
  )
}

export default Router
