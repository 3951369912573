import React from 'react'
import './index.less'

const LayoutFooter = () => {
  const date = new Date()
  const curYear = date.getFullYear()

  return (
    <div className="layout-footer">
      <p>© {curYear} 武汉亘星智能技术有限公司 版权所有</p>
    </div>
  )
}

export default LayoutFooter
