/* eslint-disable no-unused-vars */
import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import routers from '@/router/config'
import { copyArray } from '@/assets/scripts/utils'
import './index.less'

const layoutPage = (props) => {
  const { path, url } = useRouteMatch()
  const flatRoutes = []

  const _routers = copyArray(routers).filter((router) => {
    let hasItem = router.path === path
    if (hasItem) {
      delete router.childrens
    }
    if (router.childrens) {
      router.childrens.forEach((child) => {
        if (child.path === path) hasItem = true
      })
    }
    return hasItem
  })

  const eachRouters = (routes, isChild) => {
    routes.forEach((route) => {
      // route.url = url
      if (isChild) {
        if (route.path === path) {
          route.path = url
          flatRoutes.push(route)
        }
      } else {
        flatRoutes.push(route)
      }
      if (route.childrens) {
        eachRouters(route.childrens, true)
      }
    })
  }

  eachRouters(_routers)

  const getPageClassName = () => {
    let _paths = path.split('/').filter((item) => {
      return item && item.search(':') < 0
    })
    if (_paths.length === 0) {
      _paths.push('admin')
    }
    return _paths.join('-') + '-page'
  }

  return (
    <div className={`layout-page container ${getPageClassName()}`}>
      {/* <div className="page-header">
        <Breadcrumb>
          {flatRoutes.map((item) => (
            <Breadcrumb.Item key={item.path}>
              <Link to={item.path}>{item.name}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div> */}
      <div className={`page-main ${props.noSpace ? 'no__space' : ''}`}>
        {props.children}
      </div>
    </div>
  )
}

export default layoutPage
