import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import routers from '@/router/config'
import CONFIG from '@/config/config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { isExternal } from '@/assets/scripts/utils'
const SiderMenus = ({ roles }) => {
  const history = useHistory(),
    match = useRouteMatch()

  const menus = routers.filter((item) => {
    let _role = CONFIG.ROLES[0]
    if (roles && item.roles) {
      roles.forEach((role) => {
        _role = item.roles.find((name) => name === role)
      })
    }
    return (_role && item.sider === undefined) || item.sider
  })

  const [active, setActive] = useState(menus.length > 0 ? menus[0].path : '/')

  useEffect(() => {
    let pathName = match.path
    setActive(pathName)
  }, [])

  const navigateTo = (path) => {
    if (isExternal(path)) {
      window.open(path)
    } else {
      history.push(path)
    }
    return !isExternal(path)
  }

  const handleMenuClick = (item) => {
    navigateTo(item.path) && setActive(item.path)
  }

  const getClassName = (item) => {
    const names = ['sider-menus-item', 'clearfix']
    if (active.search(item.path) === 0) {
      names.push('active')
    }
    if (item.line) {
      names.push('line')
    }
    return names.join(' ')
  }

  return (
    <ul className="sider-menus">
      {menus.map((item, index) => (
        <li
          className={getClassName(item)}
          key={index}
          onClick={() => {
            handleMenuClick(item)
          }}
        >
          <Tooltip placement="right" title={item.name}>
            <img
              className="menus-item__icon"
              src={`http://gxzn-free.oss-cn-zhangjiakou.aliyuncs.com/icon/pmd/route_icons/icon${item.icon}_hover.png`}
            />
          </Tooltip>
          <label className="menus-item__text">{item.name}</label>
          <RightOutlined className="menus-item__arrow" />
        </li>
      ))}
    </ul>
  )
}

SiderMenus.propTypes = {
  roles: PropTypes.array
}

export default SiderMenus
