import Request, { UploadModel } from './index'
export const setLogin = (data) => {
  return Request.post({
    contentType: 'json',
    url: 'auth/login',
    params: data
  })
}

export const getUserInfo = (data) => {
  return Request.post({
    contentType: 'json',
    url: 'app/who',
    params: data
  })
}

export const getResultList = (data) => {
  return Request.post({
    url: 'app/quest/detail',
    params: data
  })
}

export const uploadModel = (data) => {
  return UploadModel.post({
    contentType: 'multipart',
    url: 'app/uploadFile4IM',
    params: data
  })
}
