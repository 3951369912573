import React from 'react'
import { Link } from 'react-router-dom'
import { Result, Button, Spin } from 'antd'

const Loading = ({ error }) => {
  const loadResult = (
    <div style={{ textAlign: 'center' }}>
      <Spin size="large" style={{ marginTop: '200px' }} />
    </div>
  )

  const errorResult = (
    <Result
      title="LOAD ERROR"
      subTitle={JSON.stringify(error)}
      extra={
        <Button type="primary">
          <Link to="/">返回首页</Link>
        </Button>
      }
    />
  )

  return error ? errorResult : loadResult
}

export default Loading
