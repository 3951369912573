const userInfo = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_USER_INFO':
      
      return {
        ...state,
        ...action.data
      }
    case 'REMOVE_USER_INFO':
      return {}
    default:
      return state
  }
}

export default userInfo
