export default [
  {
    path: '/login',
    layout: false,
    sider: false,
    component: () => import('@/containers/Login')
  },
  // {
  //   path: '/',
  //   sider: false,
  //   icon: '8',
  //   name: '公司管理',
  //   roles: ['superAdmin'],
  //   component: () => import('@/containers/Admin/List')
  // },
  // {
  //   path: '/dashboard',
  //   roles: ['superAdmin', 'user'],
  //   icon: '00',
  //   name: '公司管理',
  //   component: () => import('@/containers/Dashboard')
  // },
  {
    path: '/admin',
    icon: '8',
    name: '公司管理',
    roles: ['superAdmin'],
    component: () => import('@/containers/Admin/List'),
    childrens: [
      {
        roles: ['superAdmin', 'companyAdmin'],
        path: '/detail/:id',
        name: '公司详情',
        component: () => import('@/containers/Admin/Detail')
      }
    ]
  },
  {
    path: '/project',
    icon: '8',
    name: '项目管理',
    roles: ['superAdmin', 'companyAdmin', 'user'],
    childrens: [
      {
        path: '/:id?',
        name: '项目列表',
        component: () => import('@/containers/Project/List')
      },
      {
        path: '/detail/:id',
        name: '任务详情',
        component: () => import('@/containers/Project/Detail')
      }
    ]
  },

  {
    path: '/clothing',
    icon: '8',
    name: '设计协同',
    roles: ['superAdmin', 'companyAdmin', 'user'],
    childrens: [
      {
        path: '/:id?',
        name: '服装列表',
        component: () => import('@/containers/Clothing/List')
      },
      {
        path: '/version/:id?',
        name: '版本列表',
        component: () => import('@/containers/Clothing/Version')
      },
      {
        path: '/detail/:id',
        name: '版本详情',
        component: () => import('@/containers/Clothing/Detail')
      }
    ]
  },
  {
    path:'http://unity.model.glinsunai.com/',
    icon: '8',
    name: '定制营销'
  },
  // {
  //   path:'http://three.model.glinsunai.com/#/detail/0',
  //   icon: '8',
  //   name: '模型库'
  // },
  {
    path:'/model/:id',
    layout: false,
    sider: false,
    component:()=>import('@/components/Common/ModelShare')
  },
  {
    path: '/404',
    layout: false,
    sider: false,
    component: () => import('@/components/Common/NotFound')
  }
]
